<template>
  <div id="sidenav" class="sidenav">
    <div id="imgNav" class="imgNav">
      <div class="card">
        <div class="card-body" v-if="this.$store.state.division">
          <div style="font-size: 1.2em; color: chocolate">
            {{ this.$store.state.companyName }}
          </div>
        </div>
      </div>
    </div>

    <div>
      <div class="card">
        <div class="card-body">
          <form @submit.prevent="search">
            <div style="display: flex">
              <input
                class="text-center form-control-plaintext"
                placeholder="Loan ID / NIC"
                v-model="keyword"
              />
              <button type="submit" class="btn btn-link">
                <i class="bi bi-search"></i>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div id="mnu" class="mnu">
      <nav class="tree-nav">
        <details class="tree-nav__item is-expandable">
          <summary class="tree-nav__item-title">
            <router-link :to="'/'" class="tree-nav__item-title"
              >Home
            </router-link>
          </summary>
        </details>
        <details class="tree-nav__item is-expandable">
          <summary class="tree-nav__item-title">Customers</summary>
          <div class="tree-nav__item">
            <router-link :to="'/customers'" class="tree-nav__item-title"
              ><i class="bi bi-dot"></i> Customers</router-link
            >
            <router-link
              :to="'/customers_formation'"
              class="tree-nav__item-title"
              ><i class="bi bi-dot"></i> Center Formation</router-link
            >
          </div>
        </details>

        <details class="tree-nav__item is-expandable">
          <summary class="tree-nav__item-title">Loans</summary>
          <details class="tree-nav__item is-expandable">
            <summary class="tree-nav__item-title">Loans</summary>
            <div class="tree-nav__item">
              <router-link
                :to="'/loan_registration'"
                class="tree-nav__item-title"
                ><i class="bi bi-dot"></i> Create</router-link
              >
              <router-link :to="'/loan_renew'" class="tree-nav__item-title"
                ><i class="bi bi-dot"></i> Renew</router-link
              >
              <router-link :to="'/loan_payment'" class="tree-nav__item-title"
                ><i class="bi bi-dot"></i> Payment & Cancel</router-link
              >
              <router-link :to="'/loan_cancel'" class="tree-nav__item-title"
                ><i class="bi bi-dot"></i> Loan Cancel</router-link
              >
              <router-link :to="'/loan_ov'" class="tree-nav__item-title"
                ><i class="bi bi-dot"></i> Overview
              </router-link>
            </div>
          </details>
          <details class="tree-nav__item is-expandable">
            <summary class="tree-nav__item-title">Products</summary>
            <div class="tree-nav__item">
              <router-link :to="'/loan_products'" class="tree-nav__item-title"
                ><i class="bi bi-dot"></i> Products</router-link
              >
            </div>
          </details>
        </details>
        <details class="tree-nav__item is-expandable">
          <summary class="tree-nav__item-title">Cash Entries</summary>
          <div class="tree-nav__item">
            <router-link :to="'/user_expences_add'" class="tree-nav__item-title"
              ><i class="bi bi-dot"></i>My Expenses</router-link
            >
            <router-link :to="'/bank_deposits_add'" class="tree-nav__item-title"
              ><i class="bi bi-dot"></i>My Bank Deposits</router-link
            >
          </div>
        </details>
        <details class="tree-nav__item is-expandable">
          <summary class="tree-nav__item-title">Calender</summary>
          <details class="tree-nav__item is-expandable">
            <summary class="tree-nav__item-title">Holidays</summary>
            <div class="tree-nav__item">
              <router-link
                :to="'/calender_holdays'"
                class="tree-nav__item-title"
                ><i class="bi bi-dot"></i> Holiday Calender
              </router-link>
            </div>
          </details>
        </details>
        <details class="tree-nav__item is-expandable">
          <summary class="tree-nav__item-title">Reports</summary>
          <details class="tree-nav__item is-expandable">
            <summary class="tree-nav__item-title">My Reports</summary>
            <div class="tree-nav__item">
              <router-link
                :to="'/report_my_loan_balance'"
                class="tree-nav__item-title"
                ><i class="bi bi-dot"></i>Loan Balance
              </router-link>
              <router-link
                :to="'/report_my_loan_day_due'"
                class="tree-nav__item-title"
                ><i class="bi bi-dot"></i>Daily Due</router-link
              >
              <router-link
                :to="'/report_my_loan_arrearse'"
                class="tree-nav__item-title"
                ><i class="bi bi-dot"></i>Arrearse</router-link
              >
            </div>
          </details>
          <details class="tree-nav__item is-expandable" v-if="AdminMenu">
            <summary class="tree-nav__item-title">Branch & Center</summary>
            <div class="tree-nav__item">
              <router-link
                :to="'/loan_report_branch'"
                class="tree-nav__item-title"
                ><i class="bi bi-dot"></i>Branch Report</router-link
              >
              <router-link
                :to="'/loan_report_center'"
                class="tree-nav__item-title"
                ><i class="bi bi-dot"></i>Center Report</router-link
              >
            </div>
          </details>
          <details class="tree-nav__item is-expandable" v-if="AdminMenu">
            <summary class="tree-nav__item-title">Loan Officers</summary>
            <div class="tree-nav__item">
              <router-link
                :to="'/report_officers_branch'"
                class="tree-nav__item-title"
                ><i class="bi bi-dot"></i>Branch Summary</router-link
              >
            </div>
          </details>
          <details class="tree-nav__item is-expandable" v-if="AdminMenu">
            <summary class="tree-nav__item-title">Loss - Profit</summary>
            <div class="tree-nav__item">
              <router-link
                :to="'/report_loss_profit'"
                class="tree-nav__item-title"
                ><i class="bi bi-dot"></i>Loss Profit Report</router-link
              >
            </div>
          </details>
        </details>

        <details class="tree-nav__item is-expandable" v-if="AdminMenu">
          <summary class="tree-nav__item-title">Manage</summary>
          <details class="tree-nav__item is-expandable">
            <summary class="tree-nav__item-title">Officer</summary>
            <div class="tree-nav__item">
              <router-link :to="'/day_cash_issue'" class="tree-nav__item-title"
                ><i class="bi bi-dot"></i>Day Cash</router-link
              >
            </div>
          </details>
          <details class="tree-nav__item is-expandable">
            <summary class="tree-nav__item-title">Cash & Bank Entries</summary>
            <div class="tree-nav__item">
              <router-link :to="'/day_cash_manage'" class="tree-nav__item-title"
                ><i class="bi bi-dot"></i>Expenses Entry</router-link
              >
              <router-link
                :to="'/bank_deposits_manage'"
                class="tree-nav__item-title"
                ><i class="bi bi-dot"></i>Bank Deposit Entry</router-link
              >
            </div>
          </details>
          <details class="tree-nav__item is-expandable">
            <summary class="tree-nav__item-title">Centers</summary>
            <div class="tree-nav__item">
              <router-link :to="'/system_centers'" class="tree-nav__item-title"
                ><i class="bi bi-dot"></i> Registration</router-link
              >
            </div>
          </details>
        </details>
        <details class="tree-nav__item is-expandable" v-if="AdminMenu">
          <summary class="tree-nav__item-title">Admin</summary>
          <details class="tree-nav__item is-expandable">
            <summary class="tree-nav__item-title">Users</summary>
            <div class="tree-nav__item">
              <router-link :to="'/users'" class="tree-nav__item-title"
                ><i class="bi bi-dot"></i> Users</router-link
              >
            </div>
          </details>
          <details class="tree-nav__item is-expandable">
            <summary class="tree-nav__item-title">Branches</summary>
            <div class="tree-nav__item">
              <router-link :to="'/system_branches'" class="tree-nav__item-title"
                ><i class="bi bi-dot"></i> Branches</router-link
              >
            </div>
          </details>
          <details class="tree-nav__item is-expandable">
            <summary class="tree-nav__item-title">Bank Accounts</summary>
            <div class="tree-nav__item">
              <router-link :to="'/bank_list'" class="tree-nav__item-title"
                ><i class="bi bi-dot"></i> Account List</router-link
              >
            </div>
          </details>
          <details class="tree-nav__item is-expandable">
            <summary class="tree-nav__item-title">My Bill</summary>
            <div class="tree-nav__item">
              <router-link :to="'/my_bill'" class="tree-nav__item-title"
                ><i class="bi bi-dot"></i> My Bill & Usage</router-link
              >
            </div>
          </details>

          <details class="tree-nav__item is-expandable" v-if="isSystem">
            <summary class="tree-nav__item-title">Admin</summary>
            <div class="tree-nav__item">
              <router-link :to="'/test'" class="tree-nav__item-title"
                ><i class="bi bi-dot"></i> Test Page</router-link
              >
              <router-link :to="'/my_bill'" class="tree-nav__item-title"
                ><i class="bi bi-dot"></i> Bill</router-link
              >
            </div>
          </details>
        </details>
      </nav>
    </div>
  </div>
</template>
<script>
import store from "@/store";
export default {
  setup() {},
  data() {
    return {
      AdminMenu: false,
      isAdmin: false,
      isSystem: false,
      role: null,
      isDivisionProduction: false,
      isDivistionSales: false,
      keyword: null,
      division: store.state.division,
    };
  },
  mounted() {
    this.checkSystem();
  },
  methods: {
    async checkSystem() {
      this.role = store.state.user.role;
      if (this.role == "System") {
        this.isSystem = true;
      }
      if (this.role == "System" || this.role == "Admin") {
        this.AdminMenu = true;
      }
    },

    async search() {
      this.$router.push({ path: "/search", query: { key: this.keyword } });
    },
  },
};
</script>
<style scoped>
.sidenav {
  transition: 00.5s;
  width: 270px;
  left: 0;
  float: left;
  flex-wrap: wrap;
  height: 100vh;
  overflow: scroll;
  background-color: rgb(37, 37, 37);
  font-size: 0.9em;
  border-right: thin solid #5f5f5f;
}

.mImg {
  width: 100%;
}

.imgNav {
  height: auto;
}

.mnu {
  height: inherit;
}

.accordion-item:last-of-type,
.accordion-button.collapsed,
.accordion-item:first-of-type {
  border-radius: 0;
}
</style>
