import { createRouter, createWebHistory } from "vue-router";

const routes = [

  {
    path: "/",
    name: "Home",
    component: () => import("../views/App/Home/HomeView"),
  },
    {
    path: "/home",
    name: "Home Page",
    component: () => import("../views/App/Home/HomeView"),
  },
  {
    path: "/search",
    name: "Search ",
    component: () => import("../views/App/Home/SearchView"),
  },
  {
    path: "/loanview",
    name: "Loan View",
    component: () => import("../views/App/Home/LoanView"),
  },
  {
    path: "/nopermission",
    name: "No Permission",
    component: () => import("../views/App/Home/PermissionError"),
  },
  {
    path: "/test",
    name: "Test",
    component: () => import("../views/App/Home/PermissionError"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Auth/LoginView"),
  },

  {
    path: "/system_offline",
    name: "System Offline",
    component: () => import("../views/Auth/SystemOffline"),
  },
  {
    path: "/users",
    name: "Users",
    component: () => import("../views/App/Users/UsersView"),
  },
  {
    path: "/users_profile",
    name: "My Profile",
    component: () => import("../views/App/Users/UserProfile"),
  },

  {
    path: "/system_branches",
    name: "System Branches",
    component: () => import("../views/App/Branches/BranchesView"),
  },
  {
    path: "/system_centers",
    name: "System Centers",
    component: () => import("../views/App/Branches/CentersView"),
  },

  {
    path: "/customers",
    name: "Customers",
    component: () => import("../views/App/Customers/CustomersView"),
  },
  {
    path: "/customers_formation",
    name: "Customers Formation",
    component: () => import("../views/App/Customers/CenterFormation"),
  },
  {
    path: "/calender_holdays",
    name: "Holidays",
    component: () => import("../views/App/Holidays/HolidaysView"),
  },
  {
    path: "/loan_products",
    name: "Loan Products",
    component: () => import("../views/App/Loans/LoanProducts"),
  },

  {
    path: "/loan_registration",
    name: "Loan Registration",
    component: () => import("../views/App/Loans/LoanRegistration"),
  },
  {
    path: "/loan_payment",
    name: "Loan Payment",
    component: () => import("../views/App/Loans/LoanPay"),
  },
  {
    path: "/loan_renew",
    name: "Loan Renew",
    component: () => import("../views/App/Loans/LoansRenew"),
  },
  {
    path: "/loan_cancel",
    name: "Loan Cancel",
    component: () => import("../views/App/Loans/LoansCancel"),
  },
  {
    path: "/loan_ov",
    name: "Loan Overview",
    component: () => import("../views/App/Loans/LoansOv"),
  },
  {
    path: "/loan_report_center",
    name: "Loan Report Center",
    component: () => import("../views/App/Loans/LoanReportCenterView"),
  },
  {
    path: "/loan_report_Branch",
    name: "Loan Report Branch",
    component: () => import("../views/App/Loans/LoanReportBranchView"),
  },

  {
    path: "/report_my_loan_balance",
    name: "My Loan Balance Report ",
    component: () => import("../views/App/Reports/My/LoanBalance"),
  },
  {
    path: "/report_my_loan_day_due",
    name: "My Day Due Balance Report ",
    component: () => import("../views/App/Reports/My/LoanDayDue"),
  },
  {
    path: "/report_my_loan_arrearse",
    name: "My Arrease Report ",
    component: () => import("../views/App/Reports/My/LoanArrearse"),
  },
  {
    path: "/report_officers_branch",
    name: "Report Branch Officer",
    component: () => import("../views/App/Reports/Officer/ReportBranch"),
  },
  {
    path: "/report_loss_profit",
    name: "Report Loss Profit",
    component: () => import("../views/App/Reports/LossProfit/ReportLossProfit"),
  },

  {
    path: "/day_cash_issue",
    name: "Officer Day Cash",
    component: () => import("../views/App/DayCash/DayCash"),
  },

  {
    path: "/day_cash_manage",
    name: "Officer Cash Entry Manage",
    component: () => import("../views/App/CashEntry/ManageCashEntry.vue"),
  },

  {
    path: "/user_expences_add",
    name: "Expences",
    component: () => import("../views/App/Expences/ExpencesEntry"),
  },
  {
    path: "/bank_deposits_manage",
    name: "Manage Bank Deposit Entry",
    component: () => import("../views/App/Banks/ManageBankEntry"),
  },
  {
    path: "/bank_deposits_add",
    name: "Bank Deposit Entry",
    component: () => import("../views/App/Banks/UserBankEntry"),
  },
  {
    path: "/bank_list",
    name: "Bank List",
    component: () => import("../views/App/Banks/BankView"),
  },
  {
    path: "/my_bill",
    name: "Bill Usage",
    component: () => import("../views/App/Bill/MyBill"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

import store from "@/store";
router.beforeEach((to, from, next) => {
  document.title = "GREATWIN | " + to.name;
  if (to.name !== "Login" && !store.state.user) {
    next({ name: "Login" });
  } else {
    next(); // does not require auth, make sure to always call next()!
  }
});
export default router;
